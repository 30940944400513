import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ContactService {
    constructor(private http: HttpClient) {

    }



    sendEmail(emailModel: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        let url:string =  "https://api.emailjs.com/api/v1.0/email/send";
        return this.http.post(url, emailModel, httpOptions).pipe();
    }

   

   
}