import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ContactService } from './contact.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Website';

  addEmail: EmailModel;
  data: DataService;
  Message: string;
  currentYear: number;
  isMobile = navigator.userAgent.match(/(Mobile)/);
  constructor(public contactService: ContactService) {
  }

  ngOnInit() {
    this.addEmail = new EmailModel();
    this.data = new DataService();
    this.data.template_params = new TemplateParams();
    this.currentYear = (new Date()).getFullYear();
  }


  SendMail(form: NgForm) {
    
    if(this.addEmail.Message && this.addEmail.Email) {
      // this.data.service_id = 'webnetsolutions2020';
      // this.data.template_id = 'template_ig5yl6y';
      // this.data.user_id = 'user_Aqq1r6jt7N65SkqvAaIEQ';
      // this.data.template_params.from_name = this.addEmail.Name;
      // this.data.template_params.to_name = 'Web-netsolutions Team';
      // this.data.template_params.message = this.addEmail.Message;
      // this.data.template_params.phone_number = this.addEmail.PhoneNumber;
      // this.data.template_params.subject = this.addEmail.Subject;
      // this.data.template_params.email = this.addEmail.Email;
  
      var content = "Name: "+ this.addEmail.Name +" </br> Email: "+ this.addEmail.Email +" </br> Phone: "+ this.addEmail.PhoneNumber +" </br> Message: "+ this.addEmail.Message +"";
      this.saveTextAsFile("new.txt", content);

      // this.contactService.sendEmail(this.data).subscribe(resp => {
      //  this.Message = "Successfully send";
      // }, error => {
      //   this.Message = "Successfully send";
      // });
      this.Message = "Successfully send";
    } else {
      this.Message = "Please enter required details";
    }

    // this.addEmail = new EmailModel();
    // this.data = new DataService();

    // form.resetForm();
  }

  public saveTextAsFile(filename: string, content: string): void {
    
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    // saveAs(blob, filename);
  }

}


export class DataService {
  service_id: string;
  template_id: string;
  user_id: string;
  template_params: TemplateParams;
  constructor() {

  }
}

export class TemplateParams {
  from_name: string;
  to_name: string;
  message: string;
  phone_number: string;
  subject: string;
  email: string;
}

export class EmailModel {
  Name: string;
  Email: string;
  PhoneNumber: string;
  Message: string;
  Subject: string;

}